import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contacto = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata.title

	console.log(data)

	return (
		<Layout location={location} title={siteTitle} logo={data.logo.childImageSharp}>
			<SEO title="Computadoras singulares" />
			<h1>Contacto</h1>
			<Bio />
			<div>
				<p>
					¿Estás hart@ de oírme? Yo soy más de hablar que de escuchar, pero tambíen me gusta discutir sobre tecnología en <a>la cuenta de twitter @javiio_oficial</a>.
				</p> 
				<p>
					Si te van más los mensajes directos, puedes dejarme un mensaje usando el siguiente formulario y trataré de responder lo antes posible.
				</p>
			</div>
			<form name="contacto" netlify>
				<div>
					<label for="nombre">Tu nombre:</label>
					<input name="nombre" />
				</div>
				<div>
					<label for="nombre">Tu email:</label>
					<input name="email" />
				</div>
				<div>
					<label for="nombre">El motivo de tu mensaje:</label>
					<select name="">
						<option value="personal">Simplemente quería que supieras algo</option>
						<option value="comercial">Relación comercial</option>
					</select>
				</div>
				<div>
					<label for="nombre">El motivo de tu mensaje:</label>
					<textarea name="mensaje" />
				</div>
				<div className="submitWrapper">
					<button type="submit">Enviar</button>
				</div>
			</form>
		</Layout>
	)
}

export default Contacto

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(absolutePath: { regex: "/io-black.png/" }) {
      childImageSharp {
        fluid(maxWidth: 100, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
