/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)


  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        alignItems: 'center',
        marginBottom: rhythm(2.5),
      }}
    >
      <img src="../../img/javi.jpg"
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          marginLeft: -1,
          width: 57,
          height: 57,
          borderRadius: `100%`,
        }}
      />
      <p style={{marginBottom: 0}}>
        Explorando proyectos tecnológicos en la web3 y el potencial para abrir nuevas fronteras. <strong>{author}</strong> se sumerge en el mundo cripto de la blockchain y los NFTs.
        {` `}
        No te pierdas las novedades y <a href={`https://twitter.com/${social.twitter}`}>
          sigue nuestro twitter!
        </a>
      </p>
    </div>
  )
}

export default Bio
